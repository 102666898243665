<template>
    <el-dialog :close-on-click-modal="false" :title="isEdit?'编辑机构分类':'新增机构分类'" :visible.sync="show" width="500px" class="selfInputBox">
        <el-form v-if="formShow" ref="form" label-width="80px">

            <el-form-item label="机构分类名称" prop="">
                <el-input v-model="info.name" placeholder="请输入机构分类名称" />
            </el-form-item>

            <el-form-item label="排序" prop="">
                <el-input v-model="info.sort" placeholder="请输入排序" />
            </el-form-item>

            <!-- <el-form-item label="状态" prop="name2">
                <el-switch v-model="state" active-color="#5BD995" active-text="正常" inactive-color="#D9D9D9"
                    inactive-text="禁用" class="switchSelfBox">
                </el-switch>
            </el-form-item> -->

        </el-form>

        <span slot="footer">
            <div class="buttons">
                <el-button type="primary" @click="save">保存</el-button>
                <!-- <el-button @click="$refs.form.resetFields()">重置</el-button> -->
            </div>
        </span>

    </el-dialog>
</template>

<script>
    export default {
        // model: {
        //     prop: 'value',
        //     event: 'change'
        // },
        // props: {
        //     value: {
        //         type: Object,
        //         required: true
        //     }
        // },
        data() {
            return {
                show: false,
                formShow: false,
                state: true,

                info: {
                    name: "",
                    sort: ""
                },


                isEdit: false

            }
        },
        watch: {
            show(value) {
                if (this.show) {

                } else {
                    this.isEdit = false
                }
            }
        },
        methods: {

            open() {
                this.info = {
                    name: "",
                    sort: ""
                }

                this.formShow = true
                this.show = true
            },


            edit(val) {

                this.open()

                this.isEdit = true

                this.info = {
                    ...val
                }



                // this.$http.get('/api/admin/' + val.id).then((res) => {
                //     if (res.code == 200) {
                //         this.setInfo(res.data)
                //     }
                // }).catch((err) => {
                //     console.log(err)
                // });

            },

            save() {

                if (this.isEdit) {

                    this.$http.put('/api/depcate/' + this.info.id, this.info).then((res) => {

                        console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);

                        if (res.code != 200) { //请求错误
                            this.$message.error(res.msg);

                        }

                        if (res.code == 200) {
                            this.close()
                        }

                    }).catch((err) => {
                        console.log(err)
                    });

                } else {

                    this.$http.post('/api/depcate', this.info).then((res) => {
                        // console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);

                        if (res.code == 200) {
                            this.close()
                        }

                        if (res.code != 200) { //请求错误
                            this.$message.error(res.msg);

                        }
                    }).catch((err) => {
                        console.log(err)
                    });

                }

            },



            close() {
                this.show = false
                // this.$emit('refresh')

                this.$parent.getList();

                setTimeout(() => {
                    this.formShow = false
                }, 1000)
            },




        }
    }
</script>

<style>

</style>